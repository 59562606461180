import Vue from 'vue'
import store from '@/store/'
import {
  ACCESS_TOKEN,
} from '@/store/mutation-types'

export default function Initializer () {
  console.log(`API_URL: ${process.env.VUE_APP_API_BASE_URL}`)
  console.log('API_ls: '+Vue.ls)
  store.commit('SET_TOKEN', localStorage.getItem(ACCESS_TOKEN))

  // last step
}

const getters = {
  device: state => state.app.device,
  theme: state => state.app.theme,
  color: state => state.app.color,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  nickname: state => state.user.name,
  welcome: state => state.user.welcome,
  userInfo: state => state.user.info,
  addRouters: state => state.permission.addRouters,
  multiTab: state => state.app.multiTab,
  checkBtnPermissions: (state) => (roles) => {
    const hasIn = state.user.btnPermissions.indexOf(roles)
    return hasIn > -1
  },
  sysDictListForType: (state) => (type) => {
    if (state.app.sysDictList[type]) {
      return state.app.sysDictList[type]
    }
  }
}

export default getters

import { getAction, deleteAction, postAction } from '@/api/manage'

// 组织架构管理
const getDepartTree = (params) => getAction('/system/department/tree', params)
const getDepartList = (params) => postAction('/system/department/list', params)
const departSave = (params) => postAction('/system/department/save', params)
const getDeptInfo = (params) => getAction('/system/department/info', params)
const departDel = (params) => deleteAction('/system/department/delete', params)

// 角色管理
const getUserRoleList = (params) => postAction('/system/userRole/list', params)
const saveUserRoleInfo = (params) => postAction('/system/userRole/save', params)
const getUserRoleAll = (params) => postAction('/system/userRole/getAll', params)
const delUserRoleInfo = (params) => deleteAction('/system/userRole/delete', params)
const getUserRoleInfo = (params) => getAction('/system/userRole/info', params)
const queryTreeListForRole = (params) => getAction('/system/userRole/menuTree', params)
const queryRolePermission = (params) => getAction('/system/userRole/queryRolePermission', params)
const saveRolePermission = (params) => postAction('/system/userRole/saveRolePermission', params)

// 管理账户
const saveCompanyInfo = (params) => postAction('/system/company/save', params)
const getCompanyInfo = (params) => getAction('/system/company/getCompanyInfo', params)
const getCompanyList = (params) => postAction('/system/company/list', params)

// 字典
const getSysDictCache = (params) => postAction('/system/sysDict/getSysDictCache', params)

export {
  getDepartTree,
  getDepartList,
  departSave,
  getDeptInfo,
  departDel,
  getUserRoleList,
  queryTreeListForRole,
  queryRolePermission,
  saveUserRoleInfo,
  getUserRoleInfo,
  saveRolePermission,
  getUserRoleAll,
  delUserRoleInfo,
  saveCompanyInfo,
  getCompanyInfo,
  getCompanyList,
  getSysDictCache
}

import Vue from 'vue'
import { login, getInfo, logout } from '@/api/login'
import { getDepartList, getSysDictCache } from '@/api/api'
import { ACCESS_TOKEN } from '@/store/mutation-types'

const user = {
  state: {
    token: '',
    name: '',
    avatar: '',
    roles: [],
    info: {},
    sysMessageCount: 0,
    sysMessageList: {},
    departList: new Map(),
    roleList: new Map(),
    sysDictList: {},
    btnPermissions: {}
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, { name }) => {
      state.name = name
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_INFO: (state, info) => {
      state.info = info
    },
    SET_DEPART_LIST: (state, departList) => {
      state.departList = departList
    },
    SET_ROLE_LIST: (state, roleList) => {
      state.roleList = roleList
    },
    SET_SYS_DICT_LIST: (state, sysDictList) => {
      state.sysDictList = sysDictList
    },
    SET_SYS_MESSAGE_COUNT: (state, sysMessageCount) => {
      state.sysMessageCount = sysMessageCount
    },
    SET_SYS_MESSAGE_LIST: (state, sysMessageList) => {
      state.sysMessageList = sysMessageList
    },
    SET_HAS_BTN_PERMISSIONS: (state, btnPermissions) => {
      state.btnPermissions = btnPermissions
    }
  },

  actions: {
    // 登录
    Login ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo).then(response => {
          const result = response.result
          console.log(response)
          localStorage.setItem(ACCESS_TOKEN, result.token, 7 * 24 * 60 * 60 * 1000)
          commit('SET_TOKEN', result.token)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 获取用户信息
    GetInfo ({ commit }) {
      return new Promise((resolve, reject) => {
        getInfo().then(response => {
          const result = response.result
          if (result.accountInfo) {
            commit('SET_INFO', result.accountInfo)
          } else {
            reject(new Error('getInfo: roles must be a non-null array !'))
          }
          commit('SET_NAME', { name: result.accountInfo.companyName })
          commit('SET_AVATAR', result.accountInfo.avatar)

          // 获取部门列表
          getDepartList({}).then(res => {
            var map = new Map()
            res.result.data.map(x => {
              map.set(String(x.departCode), x)
            })
            commit('SET_DEPART_LIST', map)
          })
          // 获取字典
          getSysDictCache({}).then(res => {
            commit('SET_SYS_DICT_LIST', res.result.data)
          })
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 登出
    Logout ({ commit, state }) {
      return new Promise((resolve) => {
        logout(state.token).then(() => {
          resolve()
        }).catch(() => {
          resolve()
        }).finally(() => {
          commit('SET_TOKEN', '')
          Vue.ls.remove(ACCESS_TOKEN)
        })
      })
    }

  }
}

export default user

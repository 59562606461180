import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const routes = [
    {
        name: 'checkLogin',
        component: () => import('./view/auth/checkLogin'),
    },
    {
        name: 'checkLogin2',
        component: () => import('./view/auth/checkLogin2'),
    },
    {
        name: 'patient/yuyuejiancha/add',
        component: () => import('./view/patient/yuyuejiancha/add'),
        meta: {
            title: '预约检查'
        }
    },
    {
        name: 'patient/yuyuejiancha/myOrderCheckList',
        component: () => import('./view/patient/yuyuejiancha/myOrderCheckList'),
        meta: {
            title: '我的预约检查'
        }
    },
    {
        name: 'patient/yuyuejiancha/myOrderCheckInfo',
        component: () => import('./view/patient/yuyuejiancha/myOrderCheckInfo'),
        meta: {
            title: '预约详情'
        }
    },
    {
        name: 'patient/userCenter',
        component: () => import('./view/patient/user'),
        meta: {
            title: '会员中心'
        }
    },
    {
        name: 'patient/userCenter',
        component: () => import('./view/patient/user'),
        meta: {
            title: '会员中心'
        }
    },
    {
        name: 'patient/index',
        component: () => import('./view/patient/index'),
        meta: {
            title: '首页'
        }
    },
    {
        name: 'doctorInfo',
        component: () => import('./view/patient/doctorInfo'),
        meta: {
            title: '医生主页'
        }
    },
    {
        name: 'patient/doctorRecord',
        component: () => import('./view/patient/doctorInfo/doctorRecord'),
        meta: {
            title: '我的问诊记录'
        }
    },
    {
        name: 'doctorCommitList',
        component: () => import('./view/patient/doctorInfo/doctorCommitList'),
        meta: {
            title: '患者评价列表'
        }
    },
    {
        name: 'patient/toOrderStep1',
        component: () => import('./view/patient/order/toOrderStep1'),
        meta: {
            title: '提交问题'
        }
    },
    {
        name: 'feedback',
        component: () => import('./view/patient/user/feedback'),
        meta: {
            title: '意见反馈'
        }
    },
    {
        name: 'patient/chat',
        component: () => import('./view/patient/doctorInfo/chat'),
        meta: {
            title: '聊天页面'
        }
    },
    {
        name: 'patient/myOrder',
        component: () => import('./view/patient/user/myOrder'),
        meta: {
            title: '我的订单'
        }
    },
    {
        name: 'patient/myWater',
        component: () => import('./view/patient/user/myWater'),
        meta: {
            title: '我的预约单'
        }
    },
    {
        name: 'patient/myOrderInfo',
        component: () => import('./view/patient/user/myOrderInfo'),
        meta: {
            title: '订单详情'
        }
    },
    {
        name: 'myCommission',
        component: () => import('./view/patient/user/myCommission'),
        meta: {
            title: '我的佣金'
        }
    },
    {
        name: 'patient/myOrderCommit',
        component: () => import('./view/patient/user/myOrderCommit'),
        meta: {
            title: '订单评价'
        }
    },
    {
        name: 'patient/myBalance',
        component: () => import('./view/patient/user/myBalance'),
        meta: {
            title: '充值记录'
        }
    },
    {
        name: 'patient/myFollow',
        component: () => import('./view/patient/user/myFollow'),
        meta: {
            title: '我的关注'
        }
    },
    {
        name: 'patient/myDoctor',
        component: () => import('./view/patient/user/myDoctor'),
        meta: {
            title: '我的医生'
        }
    },
    {
        name: 'patient/agreement',
        component: () => import('./view/patient/user/agreement'),
        meta: {
            title: '协议'
        }
    },
    {
        name: 'auth/bgCustomer',
        component: () => import('./view/auth/bgCustomer'),
        meta: {
            title: '授权中'
        }
    },
    {
        name: 'patient/register',
        component: () => import('./view/patient/user/register'),
        meta: {
            title: '注册'
        }
    },
    {
        name: 'patient/myPatientUser',
        component: () => import('./view/patient/user/myPatientUser'),
        meta: {
            title: '我的家庭成员'
        }
    },
    {
        name: 'patient/myPatientUserInfo',
        component: () => import('./view/patient/user/myPatientUserInfo'),
        meta: {
            title: '家庭成员'
        }
    },
    {
        name: 'patient/toChoosePatientUser',
        component: () => import('./view/patient/order/toChoosePatientUser'),
        meta: {
            title: '选择家庭成员'
        }
    },
    {
        name: 'patient/vip',
        component: () => import('./view/patient/index/vip'),
        meta: {
            title: '闻诚会员'
        }
    },
    {
        name: 'patient/myOrgCollection',
        component: () => import('./view/patient/user/myOrgCollection'),
        meta: {
            title: '线下专家讲堂'
        }
    },
    {
        name: 'patient/myOrgCollectionInfo',
        component: () => import('./view/patient/user/myOrgCollectionInfo'),
        meta: {
            title: '线下专家讲堂详情'
        }
    },
    {
        name: 'patient/videoIndex',
        component: () => import('./view/patient/video/index'),
        meta: {
            title: '闻诚健康学院'
        }
    },
    {
        name: 'patient/videoInfo',
        component: () => import('./view/patient/video/info'),
        meta: {
            title: '专栏'
        }
    },
    {
        name: 'patient/videoPlay',
        component: () => import('./view/patient/video/videoPlay'),
        meta: {
            title: '视频'
        }
    },
    {
        name: 'patient/voicePlay',
        component: () => import('./view/patient/video/voicePlay'),
        meta: {
            title: '音频'
        }
    },
    {
        name: 'patient/myVideoSpecial',
        component: () => import('./view/patient/user/myVideoSpecial'),
        meta: {
            title: '我的健康学院'
        }
    },
    {
        name: 'xingqiu/index',
        component: () => import('./view/xingqiu/index'),
        meta: {
            title: '闻诚星球'
        }
    },
    {
        name: 'xingqiu/addQuestion',
        component: () => import('./view/xingqiu/addQuestion'),
        meta: {
            title: '提问'
        }
    },
    {
        name: 'xingqiu/questionInfo',
        component: () => import('./view/xingqiu/questionInfo'),
        meta: {
            title: ''
        }
    },
    {
        name: 'xingqiu/myQuestion',
        component: () => import('./view/xingqiu/myQuestion'),
        meta: {
            title: '我的咨询'
        }
    }
];

const routesDoctor = [
    {
        name: 'doctor/yuyuejiancha/myOrderCheckList',
        component: () => import('./view/doctor/yuyuejiancha/myOrderCheckList'),
        meta: {
            title: '我的预约检查'
        }
    },
    {
        name: 'doctor/yuyuejiancha/myOrderCheckInfo',
        component: () => import('./view/doctor/yuyuejiancha/myOrderCheckInfo'),
        meta: {
            title: '预约详情'
        }
    },
    {
        name: 'doctor/userCenter',
        component: () => import('./view/doctor/user'),
        meta: {
            title: '会员中心'
        }
    },
    {
        name: 'doctor/register',
        component: () => import('./view/doctor/user/register'),
        meta: {
            title: '注册'
        }
    },
    {
        name: 'doctor/myBalance',
        component: () => import('./view/doctor/user/myBalance'),
        meta: {
            title: '我的佣金'
        }
    },
    {
        name: 'doctor/myCashOut',
        component: () => import('./view/doctor/user/myCashOut'),
        meta: {
            title: '申请提现'
        }
    },
    {
        name: 'doctor/myOrder',
        component: () => import('./view/doctor/user/myOrder'),
        meta: {
            title: '我的订单'
        }
    },
    {
        name: 'doctor/myOrderInfo',
        component: () => import('./view/doctor/user/myOrderInfo'),
        meta: {
            title: '订单详情'
        }
    },
    {
        name: 'doctor/doctorRecord',
        component: () => import('./view/doctor/user/doctorRecord'),
        meta: {
            title: '我的问诊记录'
        }
    },
    {
        name: 'doctor/chat',
        component: () => import('./view/doctor/user/chat'),
        meta: {
            title: '聊天页面'
        }
    },
    {
        name: 'doctor/agreement',
        component: () => import('./view/doctor/user/agreement'),
        meta: {
            title: '协议'
        }
    },
];

for (var i = 0; i < routesDoctor.length; i++) {
    routes.push(routesDoctor[i]);
}


// add route path
routes.forEach(route => {
    route.path = route.path || '/' + (route.name || '');
});

const router = new Router({mode: 'history', routes});

router.beforeEach((to, from, next) => {
    const title = to.meta && to.meta.title;
    if (title) {
        document.title = title;
    }
    next();
});

export {
    router
};

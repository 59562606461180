import { getSysDictCache } from '@/api/api'

const app = {
    state: {
        sidebar: true,
        device: 'desktop',
        sysDictList: {}
    },
    mutations: {
        SET_SYS_DICT_LIST: (state, sysDictList) => {
            state.sysDictList = sysDictList
        },
    },
    actions: {
        setSidebar({commit}, type) {
            commit('SET_SIDEBAR_TYPE', type)
        },
        CloseSidebar({commit}) {
            commit('CLOSE_SIDEBAR')
        },
        // 获取信息
        GetInit({commit}) {
            return new Promise((resolve, reject) => {
                // 获取字典
                getSysDictCache({}).then(response => {
                    commit('SET_SYS_DICT_LIST', response.result.data)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
    }
}

export default app

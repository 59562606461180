/* eslint-disable */
/**
 * 该文件是为了按需加载，剔除掉了一些不需要的框架组件。
 * 减少了编译支持库包大小
 *
 * 当需要更多组件依赖时，在该文件加入即可
 */
import Vue from 'vue'
import {
    SysDictFilter,
    SysDictOptions,
    GetFileAccessHttpUrl,
    ParseStringToStar,
    formatDate,
    SysGetAge2,
    SysGetAge
} from './../../utils/filter'

Vue.prototype.$sysDictFilter = SysDictFilter
Vue.prototype.$sysDictOptions = SysDictOptions
Vue.prototype.$sysGetAge = SysGetAge
Vue.prototype.$sysGetAge2 = SysGetAge2
Vue.prototype.$getFileAccessHttpUrl = GetFileAccessHttpUrl
Vue.prototype.$parseStringToStar = ParseStringToStar
Vue.prototype.$formatDate = formatDate

import Vue from 'vue';
import App from './App';
import {router} from './router';
import store from './store'

// import store from './store/'
// import { VueAxios } from './utils/request'
// import './core/use'
import wx from 'weixin-js-sdk'
import bootstrap from './core/bootstrap'
import './core/lazy_use'
import './utils/filter' // global filter
import thor from 'thor-x'
import 'thor-x/dist/index.css'
import {Image as VanImage} from 'vant';
import {Row, Col, Icon, Lazyload, Empty} from 'vant';
import {postAction} from '@/api/manage';

Vue.use(Lazyload, {
    lazyComponent: true
});

Vue.use(thor)
Vue.use(Row)
Vue.use(Col)
Vue.use(Icon)
Vue.use(Empty)
Vue.use(VanImage)

import { ACCESS_TOKEN } from '@/store/mutation-types'
// localStorage.setItem(ACCESS_TOKEN,'doc885327302634901504')
//localStorage.setItem(ACCESS_TOKEN,'pat923485165009899520')
store.dispatch('GetInit').then(res => {
    console.log(res)
})

router.afterEach((to, from) => {
    console.log(from);
    let _url = window.location.origin + to.fullPath
    // 非ios设备，切换路由时候进行重新签名
    if (window.__wxjs_is_wkwebview !== true) {
        postAction('/web/api/auth/getJSAPI?url=' + encodeURIComponent(_url)).then(function (_lists) {
            console.log(_lists);
            // 注入配置
            wx.config({
                debug: false,
                appId: _lists.result.data.appId,
                timestamp: _lists.result.data.timestamp,
                nonceStr: _lists.result.data.nonceStr,
                signature: _lists.result.data.signature,
                jsApiList: ['chooseImage', 'chooseWXPay', 'uploadImage', 'previewImage', 'updateTimelineShareData', 'updateAppMessageShareData', 'onMenuShareTimeline', 'chooseWXPay'
                ]
            })
        })
    }
    // 微信分享配置
    wx.ready(function () {
        // wx.onMenuShareTimeline({})
        // wx.onMenuShareAppMessage({})
    })
})

// ios 设备进入页面则进行js-sdk签名
if (window.__wxjs_is_wkwebview === true) {
    let _url = window.location.href.split('#')[0]
    postAction('/web/api/auth/getJSAPI?url=' + encodeURIComponent(_url)).then(function (res) {
        let _lists = res
        wx.config({
            debug: false,
            appId: _lists.result.data.appId,
            timestamp: _lists.result.data.timestamp,
            nonceStr: _lists.result.data.nonceStr,
            signature: _lists.result.data.signature,
            jsApiList: ['chooseImage', 'chooseWXPay', 'uploadImage', 'previewImage', 'updateTimelineShareData', 'updateAppMessageShareData', 'onMenuShareTimeline', 'chooseWXPay']
        })
    })
}

new Vue({
    router,
    store,
    created: bootstrap,
    render: h => h(App)
}).$mount('#app')

import Vue from 'vue'
import store from '@/store'

Vue.filter('NumberFormat', function (value) {
    if (!value) {
        return '0'
    }
    const intPartFormat = value.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') // 将整数部分逢三一断
    return intPartFormat
})

Vue.filter('substrs', function (value) {
    if (!value) {
        return '0'
    }
    return value.substring(5, 10);
})

Vue.filter('getAge', function (strBirthday) {
    let returnAge,
        strBirthdayArr = strBirthday.split("-"),
        birthYear = strBirthdayArr[0],
        birthMonth = strBirthdayArr[1],
        birthDay = strBirthdayArr[2],
        d = new Date(),
        nowYear = d.getFullYear(),
        nowMonth = d.getMonth() + 1,
        nowDay = d.getDate();
    if (nowYear == birthYear) {
        returnAge = 0;//同年 则为0周岁
    } else {
        var ageDiff = nowYear - birthYear; //年之差
        if (ageDiff > 0) {
            if (nowMonth == birthMonth) {
                var dayDiff = nowDay - birthDay;//日之差
                if (dayDiff < 0) {
                    returnAge = ageDiff - 1;
                } else {
                    returnAge = ageDiff;
                }
            } else {
                var monthDiff = nowMonth - birthMonth;//月之差
                if (monthDiff < 0) {
                    returnAge = ageDiff - 1;
                } else {
                    returnAge = ageDiff;
                }
            }
        } else {
            returnAge = -1;//返回-1 表示出生日期输入错误 晚于今天
        }
    }
    return returnAge;//返回周岁年龄
})

Vue.filter('orderss', function (tt) {
    if (tt == 0) {
        return "待付款"
    } else if (tt == 20) {
        return "待接单"
    } else if (tt == 30) {
        return "待完成"
    } else if (tt == 40) {
        return "待评价"
    } else if (tt == 100) {
        return "已取消"
    } else if (tt == 50) {
        return "全部订单"
    }

})

Vue.filter('substrs2', function (value) {
    if (!value) {
        return '0'
    }
    return value.substring(5, 20);
})

Vue.filter('substrs3', function (value) {
    if (!value) {
        return '0'
    }
    return value.substring(0, 10);
})

/**
 * sysdict 字典表
 * @param key key
 * @param type 类型
 * @returns {string|*} value值
 */
export const SysDictFilter = (key, type) => {
    if (key !== undefined && key !== '') {
        const dictListForType = store.getters.sysDictListForType(type)
        if (dictListForType) {
            return dictListForType[key]
        }
    }
    return ''
}

export const SysGetAge = (strBirthday) => {
    let returnAge,
        strBirthdayArr = strBirthday.split("-"),
        birthYear = strBirthdayArr[0],
        birthMonth = strBirthdayArr[1],
        birthDay = strBirthdayArr[2],
        d = new Date(),
        nowYear = d.getFullYear(),
        nowMonth = d.getMonth() + 1,
        nowDay = d.getDate();
    if (nowYear == birthYear) {
        returnAge = 0;//同年 则为0周岁
    } else {
        var ageDiff = nowYear - birthYear; //年之差
        if (ageDiff > 0) {
            if (nowMonth == birthMonth) {
                var dayDiff = nowDay - birthDay;//日之差
                if (dayDiff < 0) {
                    returnAge = ageDiff - 1;
                } else {
                    returnAge = ageDiff;
                }
            } else {
                var monthDiff = nowMonth - birthMonth;//月之差
                if (monthDiff < 0) {
                    returnAge = ageDiff - 1;
                } else {
                    returnAge = ageDiff;
                }
            }
        } else {
            returnAge = -1;//返回-1 表示出生日期输入错误 晚于今天
        }
    }
    return returnAge;//返回周岁年龄
}
export const SysGetAge2 = (strBirthday) => {
    if (strBirthday && strBirthday.indexOf('-') >= 0) {
        // let returnAge,
        //     strBirthdayArr = strBirthday.split("-"),
        //     birthYear = strBirthdayArr[0],
        //     birthMonth = strBirthdayArr[1],
        //     birthDay = strBirthdayArr[2],
        //     d = new Date(),
        //     nowYear = d.getFullYear(),
        //     nowMonth = d.getMonth() + 1,
        //     nowDay = d.getDate();
        // // if (nowYear == birthYear) {
        // //     returnAge = 0;//同年 则为0周岁
        // //    return (nowMonth-birthMonth)+'月'
        // // } else {
        // var ageDiff = nowYear - birthYear; //年之差
        // if (ageDiff > 0) {
        //     if (nowMonth == birthMonth) {
        //         var dayDiff = nowDay - birthDay;//日之差
        //         if (dayDiff < 0) {
        //             returnAge = ageDiff - 1;
        //             return returnAge + '岁' + '11月'
        //         } else if (dayDiff == 0) {
        //             return returnAge + '岁'
        //         } else {
        //             returnAge = ageDiff;
        //             return returnAge + '岁'
        //         }
        //     } else {
        //         var monthDiff = nowMonth - birthMonth;//月之差
        //
        //         if (monthDiff < 0) {
        //             returnAge = ageDiff - 1;
        //             return returnAge + '岁' + (monthDiff + 12) + '月'
        //         } else if (monthDiff == 0) {
        //             returnAge = ageDiff;
        //             return returnAge + '岁'
        //         } else {
        //             returnAge = ageDiff;
        //             return returnAge + '岁' + (monthDiff) + '月'
        //         }
        //     }
        // } else {
        //     returnAge = -1;//返回-1 表示出生日期输入错误 晚于今天
        // }
        // // }
        // return returnAge;//返回周岁年龄
        let flag = [1, 3, 5, 7, 8, 10, 12, 4, 6, 9, 11, 2];
        let start = new Date(strBirthday);
        let end = new Date();
        let year = end.getFullYear() - start.getFullYear();
        let month = end.getMonth() - start.getMonth();
        let day = end.getDate() - start.getDate();
        if (month < 0) {
            year--;
            month = end.getMonth() + (12 - start.getMonth());
        }
        if (day < 0) {
            month--;
            let index = flag.findIndex((temp) => {
                return temp === start.getMonth() + 1
            });
            let monthLength;
            if (index <= 6) {
                monthLength = 31;
            } else if (index > 6 && index <= 10) {
                monthLength = 30;
            } else {
                monthLength = 28;
            }
            day = end.getDate() + (monthLength - start.getDate());
        }
        let result = `${year}岁${month}月${day}天`;
        console.log(result)
        return result
    }
}

/**
 *  sysdict 字典表
 * @param type 类型
 * @returns {string|*} value值
 */
export const SysDictOptions = (type) => {
    if (type !== undefined && type !== '') {
        const dictListForType = store.getters.sysDictListForType(type)
        if (dictListForType) {
            return dictListForType
        }
    }
    return []
}

/**
 * 字符串保留首尾两位，中间字符串由*星号替换
 * @param str
 * @returns {*}
 * @constructor
 */
export const ParseStringToStar = (str) => {
    if(str){
        return str.length > 2 ? str.substr(0, 1) + new Array(str.length - 2).join('*') + str.substr(-1) : str
    }else{
        return str
    }
}

/**
 * 获取文件访问路径
 * @param avatar
 * @param imgerver
 * @param str
 * @returns {*}
 */
export const GetFileAccessHttpUrl = (avatar, imgerver, subStr) => {
    if (!imgerver) {
        imgerver = process.env.VUE_APP_API_BASE_URL
    }
    if (!subStr) {
        subStr = 'http'
    }
    if (avatar && avatar.indexOf(subStr) !== -1) {
        return avatar
    } else {
        return imgerver + avatar
    }
}

export const formatDate = (value, format) => {
    // 时间戳转换成日期
    let date = new Date(value);
    let y = date.getFullYear();
    let m = date.getMonth() + 1;
    let d = date.getDate();
    let h = date.getHours();
    let min = date.getMinutes();
    let s = date.getSeconds();
    let result = "";
    if (format == undefined) {
        result = `${y}-${m < 10 ? "0" + m : m}-${d < 10 ? "0" + d : d} ${
            h < 10 ? "0" + h : h
        }:${min < 10 ? "0" + min : min}:${s < 10 ? "0" + s : s}`;
    }
    if (format == "yyyy-MM-dd") {
        result = `${y}-${m < 10 ? "0" + m : m}-${d < 10 ? "0" + d : d}`;
    }
    if (format == "yyyy.MM.dd") {
        result = `${y}.${m < 10 ? "0" + m : m}.${d < 10 ? "0" + d : d}`;
    }
    if (format == "yyyy-MM") {
        result = `${y}-${m < 10 ? "0" + m : m}`;
    }
    if (format == "yyyy.MM") {
        result = `${y}.${m < 10 ? "0" + m : m}`;
    }
    if (format == "MM-dd") {
        result = `${m < 10 ? "0" + m : m}-${d < 10 ? "0" + d : d}`;
    }
    if (format == "MM.dd") {
        result = `${m < 10 ? "0" + m : m}.${d < 10 ? "0" + d : d}`;
    }
    if (format == "hh:mm") {
        result = ` ${h < 10 ? "0" + h : h}:${min < 10 ? "0" + min : min}`;
    }
    if (format == "yyyy") {
        result = `${y}`;
    }
    return result;
}

Vue.filter('formatDate', function (value, format) {
    // 时间戳转换成日期
    let date = new Date(value);
    let y = date.getFullYear();
    let m = date.getMonth() + 1;
    let d = date.getDate();
    let h = date.getHours();
    let min = date.getMinutes();
    let s = date.getSeconds();
    let result = "";
    if (format == undefined) {
        result = `${y}-${m < 10 ? "0" + m : m}-${d < 10 ? "0" + d : d} ${
            h < 10 ? "0" + h : h
        }:${min < 10 ? "0" + min : min}:${s < 10 ? "0" + s : s}`;
    }
    if (format == "yyyy-MM-dd") {
        result = `${y}-${m < 10 ? "0" + m : m}-${d < 10 ? "0" + d : d}`;
    }
    if (format == "yyyy.MM.dd") {
        result = `${y}.${m < 10 ? "0" + m : m}.${d < 10 ? "0" + d : d}`;
    }
    if (format == "yyyy-MM") {
        result = `${y}-${m < 10 ? "0" + m : m}`;
    }
    if (format == "yyyy.MM") {
        result = `${y}.${m < 10 ? "0" + m : m}`;
    }
    if (format == "MM-dd") {
        result = `${m < 10 ? "0" + m : m}-${d < 10 ? "0" + d : d}`;
    }
    if (format == "MM.dd") {
        result = `${m < 10 ? "0" + m : m}.${d < 10 ? "0" + d : d}`;
    }
    if (format == "hh:mm") {
        result = ` ${h < 10 ? "0" + h : h}:${min < 10 ? "0" + min : min}`;
    }
    if (format == "yyyy") {
        result = `${y}`;
    }
    return result;
})

import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import {VueAxios} from './axios'
import {ACCESS_TOKEN} from '@/store/mutation-types'
import Qs from 'qs'
import {Toast, Dialog} from 'vant'

// 创建 axios 实例
const service = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL, // api base_url
    timeout: 60000 // 请求超时时间
})

const err = (error) => {
    if (error.response) {
        const data = error.response.data
        const token = localStorage.getItem(ACCESS_TOKEN)
        // if (error.response.status === 403) {
        // }
        if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
            if (token) {
                store.dispatch('Logout').then(() => {
                    setTimeout(() => {
                        window.location.reload()
                    }, 1500)
                })
            }
        }
    }
    return Promise.reject(error)
}

// request interceptor
service.interceptors.request.use(config => {
    const token = localStorage.getItem(ACCESS_TOKEN)
    console.log("token:"+token)
    console.log("ACCESS_TOKEN:"+ACCESS_TOKEN)
    if (token) {
        config.headers['Access-Token'] = token // 让每个请求携带自定义 token 请根据实际情况自行修改
    }
    config.headers['Accept'] = 'application/json'
    if (!config.headers['Content-Type']) {
        config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
        if (config.method === 'post') {
            config.data = Qs.stringify({...config.data})
        } else {
            config.params = {...config.params}
        }
    }

    return config
}, err)

// response interceptor
service.interceptors.response.use((response) => {
    if (typeof response.data == "string") {
        response.data = JSON.parse(response.data.replace(/'/g, "\""));
    }
    if (response.data.code === '0000') {
        return response.data
    } else if (response.data.code === '8888') {
        Dialog.confirm({
            message: '您还没有登录，是否需要跳转登录？',
        })
            .then(() => {
                window.location.href = "/checkLogin?urls=" + encodeURI(window.location.href);
                // on confirm
            })
            .catch(() => {
                // on cancel
            });
        return Promise.reject(response.data.content)
    } else {
        Toast(response.data.content)
        // antDesign.Message.error(response.data.content)
        return Promise.reject(response.data.content)
    }
}, err)

const installer = {
    vm: {},
    install(Vue) {
        Vue.use(VueAxios, service)
    }
}

export {
    installer as VueAxios,
    service as axios
}
